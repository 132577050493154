import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "app-dashboard",
})
export default class Dashboard extends BaseFunction {
    private infoList: any[] = [
        {
            id: 0,
            bgColor: "bg-info",
            num: 150,
            name: "机柜",
            iconName: "cabinet",
        },
        {
            id: 1,
            bgColor: "bg-teal",
            num: 123,
            name: "服务器",
            iconName: "servers",
        },
        {
            id: 2,
            bgColor: "bg-success",
            num: 133,
            name: "供件台",
            iconName: "auto-ind",
        },
        {
            id: 3,
            bgColor: "bg-warning",
            num: 342,
            name: "小车托盘",
            iconName: "pallet",
        },
        {
            id: 4,
            bgColor: "bg-danger",
            num: 189,
            name: "PLC管理",
            iconName: "plc",
        },
        {
            id: 5,
            bgColor: "bg-purple",
            num: 230,
            name: "交换机",
            iconName: "exchange-board",
        },
        {
            id: 0,
            bgColor: "bg-info",
            num: 150,
            name: "机柜",
            iconName: "cabinet",
        },
        {
            id: 1,
            bgColor: "bg-teal",
            num: 123,
            name: "服务器",
            iconName: "servers",
        },
        {
            id: 2,
            bgColor: "bg-success",
            num: 133,
            name: "供件台",
            iconName: "auto-ind",
        },
        {
            id: 3,
            bgColor: "bg-warning",
            num: 342,
            name: "小车托盘",
            iconName: "pallet",
        },
        {
            id: 4,
            bgColor: "bg-danger",
            num: 189,
            name: "PLC管理",
            iconName: "plc",
        },
        {
            id: 5,
            bgColor: "bg-purple",
            num: 230,
            name: "交换机",
            iconName: "exchange-board",
        },
        {
            id: 0,
            bgColor: "bg-info",
            num: 150,
            name: "机柜",
            iconName: "cabinet",
        },
        {
            id: 1,
            bgColor: "bg-teal",
            num: 123,
            name: "服务器",
            iconName: "servers",
        },
        {
            id: 2,
            bgColor: "bg-success",
            num: 133,
            name: "供件台",
            iconName: "auto-ind",
        },
        {
            id: 3,
            bgColor: "bg-warning",
            num: 342,
            name: "小车托盘",
            iconName: "pallet",
        },
        {
            id: 4,
            bgColor: "bg-danger",
            num: 189,
            name: "PLC管理",
            iconName: "plc",
        },
        {
            id: 5,
            bgColor: "bg-purple",
            num: 230,
            name: "交换机",
            iconName: "exchange-board",
        },
        {
            id: 0,
            bgColor: "bg-info",
            num: 150,
            name: "机柜",
            iconName: "cabinet",
        },
        {
            id: 1,
            bgColor: "bg-teal",
            num: 123,
            name: "服务器",
            iconName: "servers",
        },
        {
            id: 2,
            bgColor: "bg-success",
            num: 133,
            name: "供件台",
            iconName: "auto-ind",
        },
        {
            id: 3,
            bgColor: "bg-warning",
            num: 342,
            name: "小车托盘",
            iconName: "pallet",
        },
        {
            id: 4,
            bgColor: "bg-danger",
            num: 189,
            name: "PLC管理",
            iconName: "plc",
        },
        {
            id: 5,
            bgColor: "bg-purple",
            num: 230,
            name: "交换机",
            iconName: "exchange-board",
        },
    ];
}
